<template>
  <div>
    <v-container fluid class="py-6">
      <v-row class="my-5">
        <v-col cols="12">
          <v-card class="card-shadow border-radius-xl">
              <div class="px-6 py-6">
                  <h3 class="text-h5 font-weight-bold text-typo">Edit User</h3>
              </div>
              <create-or-edit></create-or-edit>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import CreateOrEdit from './components/CreateOrEdit';
import axiosIns from '../../../plugins/axios';

export default {
  name: "EditProject",
  components: {
      CreateOrEdit,
  },
  data: () => {
      return {
      };
  },
  computed: {
  },
  created () {
  },
  methods: {
  },
};
</script>
