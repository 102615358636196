<template>
  <div>
      <div class="px-6 pb-6 pt-0">
          <v-row>
              <v-col cols="4">
                  <v-text-field
                      color="#e91e63"
                      label="Name"
                      placeholder="Name"
                      class="font-size-input input-style"
                      v-model="form.name"
                      outlined
                      dense
                  >
                  </v-text-field>
              </v-col>
              <v-col cols="4">
                  <v-text-field
                      color="#e91e63"
                      label="Email"
                      placeholder="Email"
                      class="font-size-input input-style"
                      v-model="form.email"
                      outlined
                      dense
                  >
                  </v-text-field>
              </v-col>
              <v-col cols="4">
                  <v-text-field
                      color="#e91e63"
                      label="Password"
                      placeholder="Password"
                      class="font-size-input input-style"
                      v-model="form.password"
                      outlined
                      dense
                  >
                  </v-text-field>
              </v-col>
              <v-col cols="6">
                  <template v-if="$route.params.id">
                      <v-btn
                          color="#cb0c9f"
                          class="
                        font-weight-bolder
                        btn-default
                        bg-gradient-default
                        py-4
                        px-8
                        ms-auto
                        mt-sm-auto mt-4
                      "
                          small
                          @click="createOrEditUser()"
                      >
                          Edit
                      </v-btn>
                  </template>
                  <template v-else>
                      <v-btn
                          color="#cb0c9f"
                          class="
                        font-weight-bolder
                        btn-default
                        bg-gradient-default
                        py-4
                        px-8
                        ms-auto
                        mt-sm-auto mt-4
                      "
                          small
                          @click="createOrEditUser()"
                      >
                          Create
                      </v-btn>
                  </template>
              </v-col>
          </v-row>
      </div>
  </div>
</template>
<script>
import axiosIns from '../../../../plugins/axios';

export default {
  name: "CreateOrEdit",
  components: {},
  data: () => {
      return {
          form: {
              name: '',
              email: '',
              password: '',
          },
      };
  },
  computed: {
  },
  beforeMount () {
      if (this.$route.params.id) {
          this.loadUser();
      }
  },
  methods: {
      async loadUser() {
          const response = await axiosIns.get('/api/users/'+this.$route.params.id);
          this.form = response.data.user;
      },
      createOrEditUser() {
          if (this.$route.params.id) {
              this.editUser();
          } else {
              this.createUser();
          }
      },
      editUser() {
          axiosIns.post('/api/users/'+this.$route.params.id, this.form)
              .then((response) => {
                  console.log(response);
                  this.$router.push('/users');
              })
              .catch((error) => {
                  this.$store.commit('app/SNACKBAR', {
                      show: true,
                      body: error.response.data.message,
                      type: 'error',
                  });
              });
      },
      createUser() {
          axiosIns.post('/api/users', this.form)
              .then((response) => {
                  this.$router.push('/users');
              })
              .catch((error) => {
                  this.$store.commit('app/SNACKBAR', {
                      show: true,
                      body: error.response.data.message,
                      type: 'error',
                  });
              });
      },
  }
};
</script>
